import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const About = () => {
  return (
    <Container id="about" className="my-5">
      <h2 className="text-center mb-4">About Us</h2>
      <Row>
        <Col md="6">
          <p>
            At TechStartup, we are committed to innovation in the publishing sector. Our mission is to provide high-quality educational resources using advanced technology to enhance the learning experience.
          </p>
        </Col>
        <Col md="6">
          <p>
            Our team is composed of experts in technology, education, and design, all working together to create solutions that make a real difference in education. We pride ourselves on our dedication to excellence and innovation.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
