import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Input, Button, Form, FormGroup, InputGroup } from 'reactstrap';

const EditableItem = ({ item, displayField, onSave, onCancel }) => {
  const [formData, setFormData] = useState(item);

  const handleChange = (e) => {
    const { name, value } = e.target;

    var schema = formData;
    var pList = displayField.split('.');
    var len = pList.length;
    for(var i = 0; i < len-1; i++) {
        var elem = pList[i];
        if( !schema[elem] ) schema[elem] = {}
        schema = schema[elem];
    }
    schema[pList[len-1]] = value;

    setFormData({
      ...formData,
      [name]: formData[name]
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <Form onSubmit={handleSubmit}>
        <FormGroup>
            <InputGroup>
                <Input type="text" name={displayField.split('.')[0]} value={displayField.split('.').reduce((o, i) => o[i], formData)} onChange={handleChange} />
                <Button color="link" size="sm" onClick={handleSubmit}>
                    <FontAwesomeIcon icon="check" />
                </Button>
                <Button color="link" size="sm" onClick={onCancel}>
                    <FontAwesomeIcon icon="times" />
                </Button>
            </InputGroup>
        </FormGroup>
    </Form>
  );
};

export default EditableItem;
