import React from "react";
import { Container, Button } from 'reactstrap';

const Hero = () => {
  return (
    <div className="hero-section text-center text-white">
      <Container className="bg-secondary py-5">
        <h1 className="display-3">Welcome to Context Passages</h1>
        <p className="lead">Innovating the publishing industry with advanced technology.</p>
        <Button color="primary" href="#features">Learn More</Button>
      </Container>
    </div>
  );
};

export default Hero;
