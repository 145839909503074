import React, { useEffect, useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { getConfig } from "../config";

import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { useAuth0 } from "@auth0/auth0-react";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [roles, setRoles] = useState({
    basic: false,
    admin: false,
    editor: false,
  });
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
    // getAccessTokenSilently,
  } = useAuth0();
  const toggle = () => setIsOpen(!isOpen);

  const logoutWithRedirect = () =>
    logout({
        logoutParams: {
          returnTo: window.location.origin,
        }
    });

    // const { domain } = getConfig();
    // const getClaims = async () => {
    //   try {
    //     const token = await getAccessTokenSilently();
    //     // const roleId = "rol_3FMObWZmnAk6cWi1";
    //     // const url = `https://${domain}/api/v2/roles/${roleId}/permissions`;
    //     const url = `https://www.kigolo.com/services/roles/rol_3FMObWZmnAk6cWi1/permissions`;
    //     const response = await fetch(url, {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     });
  
    //     const responseData = await response.json();
  
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };
  
  useEffect(() => {
    if (user) {
      setRoles({
        basic: user['kigolo/roles'].includes("basic"),
        admin: user['kigolo/roles'].includes("admin"),
        editor: user['kigolo/roles'].includes("editor"),
      });
      // getClaims();
    }
  }, [user]);

  return (
    <div className="nav-container">
      <Navbar color="light" light expand="md" container={false}>
        <Container>
          <NavbarBrand href="/">Context Passages</NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar style={{minHeight: "66px"}}>
            <Nav className="mr-auto" navbar>
              {isAuthenticated && (roles.admin || roles.editor) && (
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                Edit
                </DropdownToggle>
                <DropdownMenu>
                  {roles.admin && (
                  <DropdownItem href="/edit-parameters">Parameters</DropdownItem>
                  )}
                  {(roles.admin || roles.editor) && (
                  <DropdownItem href="/edit-passages">Passages</DropdownItem>
                  )}
                  {/* <DropdownItem divider />
                  <DropdownItem>Reset</DropdownItem> */}
                </DropdownMenu>
              </UncontrolledDropdown>
              )}
              {isAuthenticated && (
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/passages"
                    exact
                    activeClassName="router-link-exact-active"
                  >
                    Passages
                  </NavLink>
                </NavItem>
              )}
            </Nav>
            <Nav className="d-none d-md-block" navbar>
              {!isAuthenticated && (
                <NavItem>
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    className="btn-margin"
                    onClick={() => loginWithRedirect()}
                  >
                    Log in
                  </Button>
                </NavItem>
              )}
              {isAuthenticated && (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret id="profileDropDown">
                    <img
                      src={user.picture}
                      alt="Profile"
                      className="nav-user-profile rounded-circle"
                      width="50"
                    />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem header>{user.name}</DropdownItem>
                    <DropdownItem
                      tag={RouterNavLink}
                      to="/profile"
                      className="dropdown-profile"
                      activeClassName="router-link-exact-active"
                    >
                      <FontAwesomeIcon icon="user" className="mr-3" /> Profile
                    </DropdownItem>
                    <DropdownItem
                      id="qsLogoutBtn"
                      onClick={() => logoutWithRedirect()}
                    >
                      <FontAwesomeIcon icon="power-off" className="mr-3" /> Log
                      out
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </Nav>
            {!isAuthenticated && (
              <Nav className="d-md-none" navbar>
                <NavItem>
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    block
                    onClick={() => loginWithRedirect({})}
                  >
                    Log in
                  </Button>
                </NavItem>
              </Nav>
            )}
            {isAuthenticated && (
              <Nav
                className="d-md-none justify-content-between"
                navbar
                style={{ minHeight: 170 }}
              >
                <NavItem>
                  <span className="user-info">
                    <img
                      src={user.picture}
                      alt="Profile"
                      className="nav-user-profile d-inline-block rounded-circle mr-3"
                      width="50"
                    />
                    <h6 className="d-inline-block">{user.name}</h6>
                  </span>
                </NavItem>
                <NavItem>
                  <FontAwesomeIcon icon="user" className="mr-3" />
                  <RouterNavLink
                    to="/profile"
                    activeClassName="router-link-exact-active"
                  >
                    Profile
                  </RouterNavLink>
                </NavItem>
                <NavItem>
                  <FontAwesomeIcon icon="power-off" className="mr-3" />
                  <RouterNavLink
                    to="#"
                    id="qsLogoutBtn"
                    onClick={() => logoutWithRedirect()}
                  >
                    Log out
                  </RouterNavLink>
                </NavItem>
              </Nav>
            )}
          </Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBar;
