import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useRef } from 'react';
import { Button, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import EditableItem from './EditableItem';

const DataList = ({ data, displayField, edit, onItemClick, onSave, onCancel, options}) => {

  const [activeIndex, setActiveIndex] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [addMode, setAddMode] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [currentData, setCurrentData] = useState([]);
  const listEndRef = useRef(null);

  useEffect(() => {
    if (data) {
      setCurrentData(data);
      const _addMode = data.filter(item => item.id === 0).length > 0;
      setAddMode(_addMode);
      if (_addMode) {
        setActiveIndex(data.length - 1);
        setTimeout(() => {
          listEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }, 200);
      } else if (data.length < currentData.length) {
        setActiveIndex((activeIndex > 0 && (activeIndex - 1)) || 0);
      }
      if (edit) {
        setEditMode(true);
      }
    }
  }, [data]);

  const handleItemClick = (index, item, e) => {
    const { dataset } = e.currentTarget;
    e.stopPropagation();
    setActiveIndex(index);
    if (dataset.action === 'delete') {
      setOpenDeleteConfirmation(true);
    } else if (options?.editable) {
      if (!editMode) {
        setEditMode(!editMode);
      }
    } else {
      onItemClick(index, item, "select");
    }
  };

  const handleSave = (item) => {
    onSave(item);
    setEditMode(false);
  };

  const handleCancel = (index, item, e) => {
    onCancel();
    setEditMode(false);
  };

  const handleDeleteConfirmationClick = (e) => {
    const { dataset } = e.currentTarget;
    setOpenDeleteConfirmation(false);
    if (dataset.action !== 'cancel') {
      // onItemClick(activeIndex, currentData[activeIndex], "delete");
      currentData[activeIndex].status = "deleted";
      onSave(currentData[activeIndex]);
    }
  };

  return (
    <>
    <ListGroup flush={options?.editable}>
      {currentData?.map((item, index) => (
        <ListGroupItem key={index} active={!options?.editable && index === activeIndex} tag={(options?.editable && "li") || "a"} href={(options?.editable && "a") || "#"} action onClick={(e) => handleItemClick(index, item, e)}>
          {editMode && index === activeIndex && (
            <>
              <EditableItem item={item} displayField={displayField} onSave={(item) => handleSave(item)} onCancel={handleCancel} />
            </>
          )}
          {(!editMode || index !== activeIndex) && (
            <>
              {displayField.split('.').reduce((o, i) => o[i], item)}
              <Button data-action="delete" color="link" size="sm" onClick={(e) => handleItemClick(index, item, e)} className='float-right'>
                <FontAwesomeIcon icon="times" />
              </Button>
            </>
          )}
        </ListGroupItem>
      ))}
      <div ref={listEndRef} />
    </ListGroup>
    <Modal isOpen={openDeleteConfirmation} backdrop="static" keyboard={false}>
      <ModalHeader>Confirmation</ModalHeader>
      <ModalBody className='text-center m-4'>
        <p>Are you sure you want to delete this item?</p>
      </ModalBody>
      <ModalFooter>
          <Button color="primary" onClick={ (e) => handleDeleteConfirmationClick(e) }>Confirm</Button>{' '}
          <Button color="secondary" onClick={ (e) => handleDeleteConfirmationClick(e) } data-action="cancel">Cancel</Button>
        </ModalFooter>
    </Modal>
    </>
  );
};

export default DataList;
