import React, { useEffect, useState } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Profile from "./views/Profile";
import Passages from "./views/Passages";
import EditParameters from "./views/EditParameters";
import EditPassages from "./views/EditPassages";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";

// styles
import "./App.scss";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

const App = () => {
  const { user, isLoading, error } = useAuth0();
  const [roles, setRoles] = useState({
    basic: false,
    admin: false,
    editor: false,
  });

  useEffect(() => {
    if (user) {
      setRoles({
        basic: user['kigolo/roles'].includes("basic"),
        admin: user['kigolo/roles'].includes("admin"),
        editor: user['kigolo/roles'].includes("editor"),
      });
    }
  }, [user]);

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <div className="spinner-container"><Loading/></div>;
  }

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar />
        <Container className="flex-grow-1 mt-5">
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/profile" component={Profile} />
            <Route path="/edit-parameters" component={roles.admin ? EditParameters : Home} />
            <Route path="/edit-passages" component={roles.admin || roles.editor ? EditPassages : Home} />
            <Route path="/passages" component={Passages} />
          </Switch>
        </Container>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
