import React, { Fragment } from "react";

import Hero from "../components/home/Hero";
import Features from "../components/home/Features";
import About from "../components/home/About";
import Contact from "../components/home/Contact";
import { Container } from "reactstrap";

const Home = () => (
  <Fragment>
    <Hero />
    <Container>
        <Features />
        <About />
        <Contact />
      </Container>
  </Fragment>
);

export default Home;
