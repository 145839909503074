import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Features = () => {
  return (
    <Container id="features" className="my-5">
      <h2 className="text-center mb-4">Our Features</h2>
      <Row>
        <Col md="4" className="text-center">
          <FontAwesomeIcon icon="book" size="xl" className="mb-3"/>
          <h4>High-Quality Content</h4>
          <p>We provide top-notch editorial content for educators and students.</p>
        </Col>
        <Col md="4" className="text-center">
          <FontAwesomeIcon icon="code" size="xl" className="mb-3"/>
          <h4>Innovative Technology</h4>
          <p>We implement the latest technologies in our solutions.</p>
        </Col>
        <Col md="4" className="text-center">
          <FontAwesomeIcon icon="lightbulb" size="xl" className="mb-3"/>
          <h4>Creativity and Innovation</h4>
          <p>We foster creativity and innovation in all our products.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Features;
