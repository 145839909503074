import React, { useState, useEffect } from 'react'
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";
import { getEnums } from '../utils/enums';
import Loading from "../components/Loading";
import {  Alert, Row, Col, Button } from "reactstrap";
// import DataGrid from '../components/DataGrid';
import DataList from '../components/DataList';
import EditParametersForm from '../components/parameters/EditParametersForm';

const EditParametersComponent = () => {
  const { apiOrigin, audience, languages } = getConfig();

  const [error, setError] = useState(null);
  const { statusEnum } = getEnums();

  const [parameters, setParameters] = useState([]);
  const [parameterTypes, setParameterTypes] = useState([]);
  // const [parameterValues, setParameterValues] = useState([]);
  const [selectedParameter, setSelectedParameter] = useState(null);
  // const [selectedParameterValue, setSelectedParameterValue] = useState(null);
  const [disabledNewParameter, setDisabledNewParameter] = useState(false);


  const {
    getAccessTokenSilently,
    loginWithPopup,
    getAccessTokenWithPopup,
  } = useAuth0();

  const handleConsent = async () => {
    try {
      await getAccessTokenWithPopup();
      setError(null);
    } catch (error) {
      setError(error);
    }

    await getParameters();
  };

  const handleLoginAgain = async () => {
    try {
      await loginWithPopup();
      setError(null);
    } catch (error) {
      setError(error);
    }

    await getParameters();
  };

  const getParameters = async (ids, level) => {
    try {
      const token = await getAccessTokenSilently();
      let queryString = "";
      if (ids) {
        queryString = `?ids=${ids}`;
      }
      if (level) {
        queryString = queryString ? `${queryString}&level=${level}` : `?level=${level}`;
      }

      const response = await fetch(`${apiOrigin}/contexts-passages/parameters${queryString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const responseData = await response.json();

      if (!ids){
        setParameters(responseData.data);
        getParameters(responseData.data[0].id, 1);
      } else
        setSelectedParameter(responseData.data[0]);
    } catch (error) {
      setError(error);
    }
  };
  
  const getParameterTypes = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${apiOrigin}/contexts-passages/parameterTypes`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const responseData = await response.json();

      setParameterTypes(responseData.data);
    } catch (error) {
      setError(error);
    }
  };

  const handle = (e, fn) => {
    e.preventDefault();
    fn();
  };

  const handleParameterClick = (index, item, action) => {
    if (action === 'select') {
      getParameters(item.id, 1);
    }
  };

  const handleAddParameter = () => {
    setParameters([
      ...parameters,
      {
        id: 0,
        displayName: "New Parameter",
        code: "",
        description: "",
        name: {
          defaultLanguage: "",
          translations: {
              en: "",
              es: "",
              pt: "",
              cre: "",
              fr: "",
              it: "",
              de: ""
          }
      },
        parameterTypeId: 1,
        multipleValues: false,
        parameterValues: []
      }
    ]);
  };

  const handleSaveData = async (dataToSave) => {
    if (dataToSave.status === "deleted")
      dataToSave.status = statusEnum.Deleted;
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${apiOrigin}/contexts-passages/saveParameter`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(dataToSave)
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      let updatedParameters = parameters.map(item =>
        item.id === dataToSave.id ? responseData.data : item
      );
      let updatedSelectedParameter = responseData.data;
      if (responseData.data.status === statusEnum.Deleted) {
        updatedParameters = parameters.filter(p => p.id !== dataToSave.id);
        const index = parameters.findIndex(p => p.id === dataToSave.id);
        updatedSelectedParameter = updatedParameters[(index > 0 && (index - 1)) || 0];
      }
      setParameters(updatedParameters);
      setTimeout(() => {
        setSelectedParameter(updatedSelectedParameter);
      }, 100);

      setError(null);
    } catch (error) {
      setError({error:"saving_error"});
    }
  };

  const handleCancel = () => {
    getParameters(selectedParameter.id, 1);
  };

  const handleSaveParameterValue = async (dataToSave) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${apiOrigin}/contexts-passages/saveParameterValue`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(dataToSave)
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // const updatedParameterValues = parameters.parameterV.map(item =>
      //   item.id === dataToSave.parameterId ? dataToSave : item
      // );
      // setParameters(updatedParameters);
      setError(null);
    } catch (error) {
      setError({error:"saving_error"});
    }
  };

  useEffect(() => {
      getParameterTypes();
      getParameters();
  }, []);

  useEffect(() => {
    setDisabledNewParameter(false);
    const _addMParameter = parameters.filter(item => item.id === 0);
    if (_addMParameter.length > 0) {
        setSelectedParameter(_addMParameter[0]);
        setDisabledNewParameter(true);
    }
  });

  return (
    <>
      {error?.error === "consent_required" && (
      <Row>
        <Col className="mb-5">
          <Alert color="warning">
              You need to{" "}
              <a
                href="#/"
                class="alert-link"
                onClick={(e) => handle(e, handleConsent)}
              >
                consent to get access to users api
              </a>
          </Alert>
        </Col>
      </Row>
      )}

      {error?.error === "login_required" && (
      <Row>
        <Col className="mb-5">
          <Alert color="warning">
              You need to{" "}
              <a
                href="#/"
                class="alert-link"
                onClick={(e) => handle(e, handleLoginAgain)}
              >
                log in again
              </a>
            </Alert>
        </Col>
      </Row>
        )}

      {error?.error === "saving_error" && (
      <Row>
        <Col className="mb-5">
            <Alert color="warning">
              Something went wrong when saving the data. Please try again.
            </Alert>
        </Col>
      </Row>
      )}

      {!audience && (
      <Row>
        <Col className="mb-5">
          <Alert color="warning">
              <p>
                Something went wrong with any configuration like <code>audience</code>, 
                <code>YOUR_API_IDENTIFIER</code>.
              </p>
          </Alert>
        </Col>
      </Row>
      )}

      <Row>
        <Col>
          <h2>Edit Parameters</h2>
          <p className="lead">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs="4">
          <Row>
            <Col className="text-right">
              <Button type="submit" color="primary" className="px-3" onClick={handleAddParameter} disabled={disabledNewParameter}>New</Button>
            </Col>
          </Row>
          <br />
          <Row>
            <Col className="overflow-auto" style={{maxHeight: "340px"}}>
              <DataList data={parameters} displayField="displayName" onItemClick={handleParameterClick} onSave={handleSaveData} />
            </Col>
          </Row>
        </Col>
        <Col>
          <EditParametersForm selectedItem={selectedParameter}
                              onSave={handleSaveData}
                              onCancel={handleCancel}
                              onSaveParameterValue={handleSaveParameterValue}
                              parameterTypes={parameterTypes} 
                              languages={languages} />
          {/* <DataList data={selectedParameter.parameterValuesparameterValues} displayField="displayName" /> */}
        </Col>
      </Row>
    </>
  )
}


export default withAuthenticationRequired(EditParametersComponent, {
  onRedirecting: () => <Loading />,
});
