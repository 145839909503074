import configJson from "./auth_config.json";
import configDevJson from "./auth_config_dev.json";

export function getConfig() {
  // Configure the audience here. By default, it will take whatever is in the config
  // (specified by the `audience` key) unless it's the default value of "YOUR_API_IDENTIFIER" (which
  // is what you get sometimes by using the Auth0 sample download tool from the quickstart page, if you
  // don't have an API).
  // If this resolves to `null`, the API page changes to show some helpful info about what to do
  // with the audience.
  const audience =
    process.env.NODE_ENV === "production"
    ? (configJson.audience && configJson.audience !== "YOUR_API_IDENTIFIER"
        ? configJson.audience
        : null)
    : (configDevJson.audience && configDevJson.audience !== "YOUR_API_IDENTIFIER"
        ? configDevJson.audience
        : null);

  return {
    apiOrigin: process.env.NODE_ENV === "production"
      ?  "https://cp-api.kigolo.com"
      : "http://localhost:5076",
    languages: ["en", "es", "pt", "cre", "fr", "it", "de"],
    domain: process.env.NODE_ENV === "production"
      ? configJson.domain
      : configDevJson.domain,
    clientId: process.env.NODE_ENV === "production"
      ? configJson.clientId
      : configDevJson.clientId,
    ...(audience ? { audience } : null),
  };
}
