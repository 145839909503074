import React from "react";
import { Spinner } from "reactstrap";

const Loading = () => (
  <div>
    <Spinner className="m-5" color="primary" />
  </div>
);

export default Loading;
