import React, { useState, useEffect } from 'react';
import { getEnums } from '../../utils/enums';
import { Form, FormGroup, Row, Col, Label, Input, Button } from 'reactstrap';
import DataList from '../DataList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EditParametersForm = ({ selectedItem, onSave, onCancel, onSaveParameterValue, parameterTypes, languages }) => {
  const [formData, setFormData] = useState({});
  const [language, setLanguage] = useState('en');
  const [editParameterValues, setEditParameterValues] = useState(false);
  const { statusEnum } = getEnums();

  useEffect(() => {
    if (selectedItem) {
      setFormData(selectedItem);
    }
  }, [selectedItem]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    var _value = value;
    if (name === 'name') {
      _value = formData.name;
      _value["translations"][language] = value;
    }
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : _value
    });
  };

  const handleChangeLanguage = (e) => {
    const { value } = e.target;
    setLanguage(value);
  };

  const handleAddParameterValue = () => {
    const newValue = {
      "id": 0,
      "parameterId": formData.id,
      "value": {
          "defaultLanguage": "",
          "translations": {
              "en": "",
              "es": "",
              "pt": "",
              "cre": "",
              "fr": "",
              "it": "",
              "de": ""
          }
      },
      "displayName": "",
      "status": 1,
    };
    setFormData({
      ...formData,
      parameterValues: [...formData.parameterValues || [], newValue],
    });
    setEditParameterValues(true);
  };

  const handleParameterValueClick = (index, item, action) => {
    if (action === 'delete') {
      item.status = statusEnum.Deleted;
      setFormData({
        ...formData,
        parameterValues: [...formData.parameterValues.filter((pv, i) => i !== index), item],
      });
      }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  const handleSaveValue = (item) => {
    onSaveParameterValue(item);
  };
  
  const handleCancel = () => {
    onCancel();
  };

  const renderFormFields = () => {
    return (
      <>
        <FormGroup>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="code">Code</Label>
                  <Input type="text" name="code" id="code" value={formData.code || ""} onChange={handleChange} invalid={formData.code === ''} maxLength="10" />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="name">Name</Label>
                  <Input type="select" id="language" name="language" value={language || 'en'} onChange={handleChangeLanguage} className='border-0 float-right'>
                    {/* <option value="">Select language</option> */}
                    {languages?.map((l, i) => (
                      <option key={l} value={l}>
                        {l}
                      </option>
                    ))}
                  </Input>
                  <Input type="text" id="name" name="name" value={formData.name?.translations[language] || ""} onChange={handleChange} invalid={formData.name?.translations[language] === ''} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="description">Description</Label>
                  <Input type="text" id="description" name="description" value={formData.description || ""} onChange={handleChange} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="type">Type</Label>
                  <Input type="select" id="type" name="type" value={formData.parameterTypeId || ''} onChange={handleChange} className='form-control'>
                    <option value="">Select an option</option>
                    {parameterTypes?.map((pt, i) => (
                      <option key={i} value={pt.id}>
                        {pt.name.defaultLanguage}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col className="my-auto text-center">
                <FormGroup check>
                  <Label check className="mt-3">
                    <Input type="checkbox" id='multipleValues' name="multipleValues" checked={formData.multipleValues} onChange={handleChange} />
                    Multiple values
                  </Label>
                </FormGroup>
              </Col>
            </Row>
        </FormGroup>
      </>
    );
  };

  if (!selectedItem) return null;

  return (
    <Form onSubmit={handleSubmit}>
      {renderFormFields()}
      <Row>
        <Col className="text-right">
          <Button type="submit" color="primary" className="px-3 mx-1">Save</Button>
          <Button type="button" color="secondary" className="px-2 mx-1" onClick={handleCancel}>Cancel</Button>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <FormGroup>
            <Label>Values</Label>
            <Button color="link" size="sm" onClick={() => handleAddParameterValue()} className="float-right mx-3" disabled={formData.id === 0}>
              <FontAwesomeIcon icon="plus" />
            </Button>
            {(formData.parameterValues?.length > 0 && (
            <DataList data={formData.parameterValues?.filter((pv, i) => pv.status !== statusEnum.Deleted)} 
                      displayField={`value.translations.${language}`} 
                      edit={editParameterValues}
                      onItemClick={handleParameterValueClick} 
                      onSave={(item) => handleSaveValue(item)}
                      onCancel={handleCancel}
                      options={{ editable: true, language: language }} />
            )) || (
              <Row>
                <Col className="text-center">
                  <p>No values</p>
                </Col>
              </Row>
            )}
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default EditParametersForm;
