import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheck, faLink, faPlus, faPowerOff, faTimes, faUser, faBook, faCode, faLightbulb } from "@fortawesome/free-solid-svg-icons";

function initFontAwesome() {
  library.add(faLink);
  library.add(faUser);
  library.add(faPowerOff);
  library.add(faCheck);
  library.add(faTimes);
  library.add(faPlus);
  library.add(faBook);
  library.add(faCode);
  library.add(faLightbulb);
}

export default initFontAwesome;
