export function getEnums() {
  const statusEnum = {
    Added: 1,
    Modified: 2,
    Deleted: 3,
  };

  return {
    languages1: ["en", "es", "pt", "cre", "fr", "it", "de"],
    ...(statusEnum ? { statusEnum } : null),
  };
}