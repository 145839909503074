import React from 'react'
import { Col, Row } from 'reactstrap';

const EditPassages = () => {
  return (
    <Row>
      <Col>
        <h2>Edit Passages</h2>
        <p className="lead">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
      </Col>
    </Row>
  )
}

export default EditPassages;