import React from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';

const Contact = () => {
  return (
    <Container id="contact" className="my-5">
      <h2 className="text-center mb-4">Contact Us</h2>
      <Row>
        <Col md={{ size: 6, offset: 3 }}>
          <Form>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input type="text" name="name" id="name" placeholder="Your name" />
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input type="email" name="email" id="email" placeholder="Your email" />
            </FormGroup>
            <FormGroup>
              <Label for="message">Message</Label>
              <Input type="textarea" name="message" id="message" placeholder="Your message" />
            </FormGroup>
            <Button color="primary" type="submit">Send</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
